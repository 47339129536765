import React from 'react';
import projects from '../data/project'; // Projeleri içe aktarın
import { Link } from 'react-router-dom';

const Projects = () => {
  return (
    <div>
      {/* Project Start */}
      <div className="container-xxl py-5">
        <div className="container">
          <div className="text-center mx-auto mb-5 wow fadeInUp" data-wow-delay="0.1s" style={{ maxWidth: 600 }}>
            <h1 className="mb-4">Projelerimiz</h1>
          </div>
          <div className="row g-4 portfolio-container wow fadeInUp" data-wow-delay="0.5s">
            {projects.map((product) => (
              <div key={product.id} className={`col-lg-4 col-md-6 portfolio-item`}>
                <div className="portfolio-img rounded overflow-hidden">
                  <img className="img-fluid" src={product.image} alt="" style={{ height: '220px', width: '100%' }} />
                  <div className="portfolio-btn">
                    <Link to={`/projeler/proje-detay/${product.id}`} className="btn btn-lg btn-outline-light rounded-circle mx-1">
                      <i className="fa fa-eye" />
                    </Link>
                  </div>
                </div>
                <div className="pt-3">
                  <hr className="text-primary w-25 my-2" />
                  <h5 className="lh-base">{product.title}</h5>
                  <a className="small fw-medium" href="https://yetisplus.com/" target="_blank" rel="noopener noreferrer">
                    Sitemizi ziyaret edin <i className="fa fa-arrow-right ms-2" />
                  </a>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      {/* Project End */}
    </div>
  );
}

export default Projects;
