import React from 'react';

const projects = [
  {
    id: 1,
    title: 'Yetiş Kullanıcı Paneli',
    image: 'img/yetisplus.jpg',
     link: 'https://customer.yetisplusdepo.com/',
     uyeol_link: 'https://customer.yetisplusdepo.com/register',
    description: 'Yetiş Kullanıcı ile ilgili içerik eklenecek',
  },
  {
    id: 2,
    title: 'Yetisplus',
    image: 'img/yetisplus.jpg',
    link: 'https://yetisplus.com/',
    uyeol_link: 'https://yetisplus.com/register',
    description: (
      <React.Fragment>
      <p>
        Hızır Global A.Ş., Türkiye'nin önde gelen lokasyon bazlı pazaryeri platformlarından biridir ve Ankara merkezli olarak faaliyet göstermektedir. Şirketimiz, yerel işletmelerle müşterileri buluşturan yenilikçi bir yaklaşıma sahiptir ve yerel ekonominin güçlenmesini desteklemeyi amaçlamaktadır.
      </p>
      <h3>Misyonumuz</h3>
      <p>
        Misyonumuz, yerel işletmeleri destekleyerek ve müşterilere kolay ve hızlı alışveriş deneyimleri sunarak topluluklarımıza katkıda bulunmaktır. Yerel ekonomileri canlandırmak ve işletmelerin büyümesine yardımcı olmak, Hızır Global A.Ş'nin temel taahhütlerindendir.
      </p>
      <h3>Aynı Gün Teslimat İle Hız</h3>
      <p>
        Hızır Global A.Ş., aynı gün teslimat hizmeti sunarak müşterilerine alışverişlerini anında kapılarına getirme fırsatı sunar. Bu hızlı ve güvenilir teslimat, müşteri memnuniyetini en üst düzeye çıkarmak için tasarlanmıştır.
      </p>
      <h3>Hızır Kurye İle Güvenilir Teslimatlar</h3>
      <p>
        Kurye operasyonlarımızı Hızır Kurye ile yürütüyoruz. Hızır Kurye, profesyonel ve güler yüzlü kuryeleriyle, teslimatlarınızı güvende ve zamanında yapmak için çalışır. Müşterilerimize güvenilir bir teslimat deneyimi sunmak için çaba harcıyoruz.
      </p>
      <h3>Yerel İşletmeleri Desteklemek</h3>
      <p>
        Hızır Global A.Ş., yerel işletmelerin büyümesini desteklemek ve yerel ekonomilere katkı sağlamak amacıyla kurulmuştur. Platformumuz, yerel işletmelere ürünlerini geniş bir müşteri kitlesine ulaştırma fırsatı sunar.
      </p>
      <h3>Bize Katılın</h3>
      <p>
        Hızır Global A.Ş. ile yerel alışveriş deneyimini yeniden keşfedin. Yerel ekonomiyi canlandırarak ve aynı gün teslimat hizmetiyle alışverişinizi daha keyifli hale getirin. Müşteri memnuniyeti ve yerel ekonomiye olan bağlılığımızla sizi bekliyoruz.
      </p>
      <p>
        Bizimle alışveriş yaparak yerel işletmeleri desteklemeye katkıda bulunun ve yerel ekonomilere güç verin.
      </p>
    </React.Fragment>
    )
  }
];

export default projects;
