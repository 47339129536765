import React from 'react';

const About = () => {
  return (
    <div>
      {/* Page Header Start */}
      <div className="container-fluid page-header py-5 mb-5">
        <div className="container py-5">
          <h1 className="display-3 text-white mb-3 animated slideInDown">Hakkımızda</h1>
          <nav aria-label="breadcrumb animated slideInDown">
            <ol className="breadcrumb">
              <li className="breadcrumb-item"><a className="text-white" href="/">Home</a></li>
              <li className="breadcrumb-item text-white active" aria-current="page">Hakkımızda</li>
            </ol>
          </nav>
        </div>
      </div>
      {/* Page Header End */}
 
      
    {/* About Start */}
    <div className="container-fluid bg-light overflow-hidden my-5 px-lg-0">
    <div className="container about px-lg-0">
        <div className="row g-0 align-items-center">
        <div className="col-lg-6 ps-lg-4 ps-md-3 wow fadeIn" data-wow-delay="0.1s" style={{ minHeight: 400 }}>
            <div className="position-relative h-100">
            <img className="img-fluid w-100 h-70" src="img/about.png" style={{ objectFit: 'cover' }} alt="About Us" />
            </div>
        </div>
        <div className="col-lg-6 about-text py-5 wow fadeIn text-dark" data-wow-delay="0.5s">
            <div className="p-lg-5 pe-lg-0 text-left">
       
            
            <p>
                <h6><strong>Biz Kimiz?</strong></h6>
                2023 yılından itibaren markaları ile Türk teknoloji ve e-ticaret sektöründe kayda değer bir 
                rol oynayan Hızır Global A.Ş., mevcut pazar, müşteriler ve iş ortaklarının problemlerine 
                eğilerek yenilikçi, şeffaf, adil, yerli ve milli bir anlayışla hareket eden bir oluşumdur. 
                Yerel işletmelerin büyümesini desteklemek ve yerel ekonomilere katkı sağlamak 
                amacıyla kurulmuştur. Bu çerçevede üreticiden tüketiciye hizmet anlayışı ilkelerimizden 
                biri olurken markalarımız, yerel işletmelere geniş bir müşteri kitlesi fırsatı sunar.
            </p>
            <p>
                <h6><strong>Amacımız</strong></h6>
                Hızır Global A.Ş. markaları çerçevesinde yerel işletmelerle müşterileri buluşturan, 
                yenilikçi yaklaşım ile hareket ederken müşterilerin beklentilerine yönelik şeffaf alışveriş 
                deneyimini ve yerel ekonominin güçlenmesini amaçlar. 
            </p>
            <p>
                <h6><strong>Misyonumuz</strong></h6>
                Markalarımız ile ekosistemimizdeki her paydaşımızın kalplerine dokunmak ve hayatlarının her anına yetişen bir kahraman olmaktır.
            </p>
            <p>
                <h6><strong>Vizyonumuz</strong></h6>
                Yenilikçi çözümlerle fark yaratan büyük istek ve inançla toplumumuza değer katan 
                küresel bir oyuncu olmaktır.
            </p>
            <p>
                <h6><strong>Değerlerimiz</strong></h6>
                Tüm paydaşların mutluluğuna odaklı, adil ve şeffaf, yerli ve milli, topluma ve çevreye karşı duyarlı, samimi, çözüm üreten, değişime ayak uyduran, dinamik.
            </p>
            </div>
        </div>
        </div>
    </div>
    </div>
    {/* About End */}


      
      
    </div>
  );
};

export default About;
