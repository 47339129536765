import React from 'react'

import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import services from '../data/service.js'; 
import projects from '../data/project.js'; 
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import LocalShippingTwoToneIcon from '@mui/icons-material/LocalShippingTwoTone';
import Groups2TwoToneIcon from '@mui/icons-material/Groups2TwoTone';
import WarehouseTwoToneIcon from '@mui/icons-material/WarehouseTwoTone';
const Home = () => {
  const { t } = useTranslation();
  return (
    <div>
      
      <div>
     

  {/* Feature Start */}
  <div className="container-xxl py-5">
      <div className="container">
        <div className="row justify-content-center g-5">
          <div className="col-12 col-md-6 col-lg-4 d-flex flex-column align-items-center wow fadeIn" data-wow-delay="0.1s">
            <div className="d-flex align-items-center mb-4">
              <div className="btn-lg-square bg-primary rounded-circle me-3">
                <LocalShippingTwoToneIcon style={{ fontSize: 30, color: 'white' }} />
              </div>
              <div>
                <h1 className="mb-0" data-toggle="counter-up">1125</h1>
                <h5 className="mb-0 text-center">Kurye</h5>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 col-lg-4 d-flex flex-column align-items-center wow fadeIn" data-wow-delay="0.3s">
            <div className="d-flex align-items-center mb-4">
              <div className="btn-lg-square bg-primary rounded-circle me-3">
                <Groups2TwoToneIcon style={{ fontSize: 30, color: 'white' }} />
              </div>
              <div>
                <h1 className="mb-0" data-toggle="counter-up">7000+</h1>
                <h5 className="mb-0 text-center">Müşteri</h5>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 col-lg-4 d-flex flex-column align-items-center wow fadeIn" data-wow-delay="0.5s">
            <div className="d-flex align-items-center mb-4">
              <div className="btn-lg-square bg-primary rounded-circle me-3">
                <WarehouseTwoToneIcon style={{ fontSize: 30, color: 'white' }} />
              </div>
              <div>
                <h1 className="mb-0" data-toggle="counter-up">65</h1>
                <h5 className="mb-0 text-center">Depo</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  



  {/* Feature Start */}
 
 {/* Projelerimiz Başlangıç */}
 <div className="container-xxl py-5">
        <div className="container">
          <div className="text-center mx-auto mb-5 wow fadeInUp" data-wow-delay="0.1s" style={{ maxWidth: 600 }}>
            <h1 className="mb-4">Projelerimiz</h1>
          </div>
          <div className="row g-4">
            {projects.map((project) => (
              <div className="col-md-6 col-lg-4 wow fadeInUp" data-wow-delay="0.1s" key={project.id}>
                <div className="service-item rounded overflow-hidden">
                  <img className="img-fluid" src={project.image} alt={project.title} />
                  <div className="position-relative p-4 pt-3">
                    <h4>{project.title}</h4>
                    <a className="small fw-medium" href={project.link} target="_blank" rel="noopener noreferrer">
                      Ziyaret edin<i className="fa fa-arrow-right ms-2" />
                    </a>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      {/* Projelerimiz Bitiş */}
  {/* Feature Start */}
  <div className="container-fluid bg-light overflow-hidden my-5 px-lg-2"> {/* px-lg-2 olarak değiştirildi */}
  <div className="container feature px-lg-0">
    <div className="row g-0 mx-lg-2"> {/* mx-lg-2 olarak değiştirildi */}
      <div className="col-lg-6 pe-lg-4 wow fadeIn" data-wow-delay="0.1s"> {/* pe-lg-4 olarak değiştirildi */}
        <div className="feature-text py-5">
          <div className="p-lg-4 ps-lg-2"> {/* p-lg-4 ve ps-lg-2 olarak değiştirildi */}
            <h2 className="mb-4">Yayındaki Uygulamalarımıza Kolayca Üye Olun,<br/> Dünyamıza Adım Atın</h2>
            <div className="row g-4 justify-content-center">
              {projects.map((project) => (
                <div className="col-md-6 col-lg-4 wow fadeInUp" data-wow-delay="0.1s" key={project.id}>
                  <div className="service-item rounded overflow-hidden">
                    <div className="position-relative p-4 pt-3">
                      <h4>{project.title}</h4>
                      <a className="small fw-medium" href={project.uyeol_link} target="_blank" rel="noopener noreferrer">
                        Ziyaret edin <i className="fa fa-arrow-right ms-2" />
                      </a>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="col-lg-6 pe-lg-4 wow fadeIn" data-wow-delay="0.5s" style={{ minHeight: 400, paddingLeft: '1rem' }}> {/* pe-lg-4 ve paddingLeft olarak değiştirildi */}
        <div className="position-relative h-100">
          <img className="position-absolute img-fluid w-100 h-100" src="img/yayinlanan.jpg" style={{ objectFit: 'cover', left: '-1rem' }} alt="" /> {/* style içeriği değiştirildi */}
        </div>
      </div>
      
    </div>
  </div>
</div>



  {/* Feature End */}
  {/* Projects Start */}
  <div className="container-xxl py-5">
          <div className="container">
            <div className="text-center mx-auto mb-5 wow fadeInUp" data-wow-delay="0.1s" style={{maxWidth: 600}}>
              <h4 className="text-primary">Hizmetlerimiz</h4>
              <h1 className="mb-4">Hızlı Teslimat ve Güvenilir Depolama Çözümleri!</h1>
            </div>
          
            <div className="row g-4 portfolio-container wow fadeInUp" data-wow-delay="0.5s">
              {services.map((service) => (
                <div key={service.id} className={`col-lg-4 col-md-6 portfolio-item `}>
                  <div className="portfolio-img rounded overflow-hidden">
                    <img className="img-fluid" src={service.image} alt="" style={{height: '220px', width: '100%'}} />
                    <div className="portfolio-btn">
                      <Link to={`/hizmetler/hizmet-detay/${service.id}`} className="btn btn-lg btn-outline-light rounded-circle mx-1"><i className="fa fa-eye" /></Link>
                    </div>
                  </div>
                  <div className="pt-3">
                  
                    <hr className="text-primary w-25 my-2" />
                    <h5 className="lh-base">{service.title}</h5>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
  {/* Projects End */}
  {/* Quote Start */}
  <div className="container-fluid bg-light overflow-hidden my-5 px-lg-0">
  <div className="container quote px-lg-0">
    <div className="row g-0 mx-lg-0">
      <div className="col-lg-6 order-lg-2 pe-lg-0 wow fadeIn" data-wow-delay="0.1s" style={{minHeight: 400}}>
        <div className="position-relative h-100">
          <img className="position-absolute img-fluid w-100 h-100" src="img/contact.jpg" style={{objectFit: 'cover', right: 0}} alt="" />
        </div>
      </div>
      <div className="col-lg-6 quote-text py-5 wow fadeIn" data-wow-delay="0.5s">
        <div className="p-lg-5 pe-lg-0">
        
          <h3 className="mb-4">İletişim</h3>
         <strong> <p className="mb-4 pb-2">İstek, öneri ve görüşlerinizi bizimle paylaşın </p></strong>
          <form>
            <div className="row g-3">
              <div className="col-12">
                <input type="text" className="form-control border-0" placeholder="İsim" style={{height: 55}} />
              </div>
              <div className="col-12">
                <input type="email" className="form-control border-0" placeholder="Email" style={{height: 55}} />
              </div>
              <div className="col-12">
                <input type="text" className="form-control border-0" placeholder="Tel" style={{height: 55}} />
              </div>
              <div className="col-12">
              </div>
              <div className="col-12">
                <textarea className="form-control border-0" placeholder="Açıklama" defaultValue={""} />
              </div>
              <div className="col-12">
                <button className="btn btn-primary rounded-pill py-3 px-5" type="submit">Gönder</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>

  {/* Quote End */}

</div>



    </div>
  )
}

export default Home
