import React from 'react';
import { NavLink } from 'react-router-dom';

const Header = () => {
  return (
    <div>
      {/* Navbar Start */}
      <nav className="navbar navbar-expand-lg bg-white navbar-light sticky-top p-0">
        <a href="/">
          <img src="/img/hizirglobal_logo.jpg" alt="Hızır Global" style={{ maxWidth: '100px' }} />
        </a>
        <button type="button" className="navbar-toggler me-4" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
          <span className="navbar-toggler-icon" />
        </button>
        <div className="collapse navbar-collapse" id="navbarCollapse">
          <div className="navbar-nav ms-auto p-4 p-lg-0">
            <NavLink exact to="/" className="nav-item nav-link" activeClassName="active">ANA SAYFA</NavLink>
            <NavLink to="/hakkimizda" className="nav-item nav-link" activeClassName="active">HAKKIMIZDA</NavLink>
            <NavLink to="/projeler" className="nav-item nav-link" activeClassName="active">PROJELER</NavLink>
            <NavLink to="/hizmetler" className="nav-item nav-link" activeClassName="active">HİZMETLER</NavLink>
            <NavLink to="/iletisim" className="nav-item nav-link" activeClassName="active">İLETİŞİM</NavLink>
          </div>
        </div>
      </nav>
      {/* Navbar End */}
    </div>
  );
}

export default Header;
