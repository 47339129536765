import React from 'react';
import { IconButton } from '@mui/material';
import XIcon from '@mui/icons-material/X';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTiktok } from '@fortawesome/free-brands-svg-icons';
import { faXTwitter } from '@fortawesome/free-brands-svg-icons';
const Footer = () => {
  return (
    <div className="container-fluid bg-dark text-body footer py-4 mt-4 wow fadeIn" data-wow-delay="0.1s">
      <div className="container">
        <div className="row g-5">
          <div className="col-lg-3 col-md-6">
            <h5 className="text-white mb-4">Adres</h5>
            <p className="mb-2"><i className="fa fa-map-marker-alt me-3" />Cevizlidere Mahallesi, 1244. Sokak No.1 İç Kapı No.13 Çankaya, Ankara</p>
            <p className="mb-2"><i className="fa fa-phone-alt me-3" />+908504410497</p>
            <p className="mb-2"><i className="fa fa-envelope me-3" />info@example.com</p>
            <div className="d-flex justify-content-center  ms-md-auto pt-2">
            <a className="btn btn-square btn-outline-light btn-social me-2" href="https://x.com/i/flow/login?redirect_after_login=%2Fyetispluscarsi" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faXTwitter} />
    </a>
 
              <a className="btn btn-square btn-outline-light btn-social me-2" href="https://www.facebook.com/people/Yeti%C5%9F-%C3%87ar%C5%9F%C4%B1/61559228634077/"><i className="fab fa-facebook-f" /></a>
              <a className="btn btn-square btn-outline-light btn-social me-2" href="https://www.youtube.com/channel/UCC-keDOCc2XnclCDJ9_xFtQ"><i className="fab fa-youtube" /></a>
              <a className="btn btn-square btn-outline-light btn-social" href="https://www.instagram.com/yetispluscarsi/"><i className="fab fa-linkedin-in" /></a>
              <a className="btn btn-square btn-outline-light btn-social me-2" href="https://www.tiktok.com/@yetispluscarsi"> <FontAwesomeIcon icon={faTiktok} /></a>

            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <h5 className="text-white mb-4">Sayfalar</h5>
            <a className="btn btn-link" href="/">Ana Sayfa</a>
            <a className="btn btn-link" href="/hakkimizda">Hakkımızda</a>
            <a className="btn btn-link" href="/projeler">Projeler</a>
            <a className="btn btn-link" href="/iletisim">İletişim</a>
          </div>
        </div>
      </div>
      <div className="container mt-4">
        <div className="copyright">
          <div className="row">
            <div className="col-md-6 text-center text-md-start mb-3 mb-md-0">
              © <a href="#">HIZIR GLOBAL AŞ</a>, All Right Reserved.
            </div>
            <div className="col-md-6 text-center text-md-end">
              {/*/*** This template is free as long as you keep the footer author’s credit link/attribution link/backlink. If you'd like to use the template without the footer author’s credit link/attribution link/backlink, you can purchase the Credit Removal License from "https://htmlcodex.com/credit-removal". Thank you for your support. *** /*/}
              Designed By <a href="https://htmlcodex.com">HTML Codex</a>
              <br />Distributed By: <a href="https://themewagon.com" target="_blank">ThemeWagon</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
